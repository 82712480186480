.system-settings-and-benefits{
    margin-top: 150px;
    margin-bottom: 50px;
    background-color: #fdf7f3;
    position: relative;
    padding-top: 80px;
    padding-bottom: 50px;
}

.system-settings-and-benefits>img{
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    inset: 0;
    border-radius: 5px;
}


.system-settings-and-benefits .header h1{
    text-align: center;
    margin-bottom: 57px;
}


.system-settings-and-benefits .question{
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #00000057;
    position: relative;
    z-index: 1;
}

.system-settings-and-benefits .row .col-12:last-child .question{
    border:none;
}

.system-settings-and-benefits .question .header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.system-settings-and-benefits .question .header button{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid #d95d13;
    background-color: transparent;
    color: #d95d13;
    font-size: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.system-settings-and-benefits .question .header h2{
    direction: rtl;
}


.system-settings-and-benefits .content{
    display: none;
}


.system-settings-and-benefits .content.active{
    display: block;
}


.system-settings-and-benefits .content ul{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    direction: rtl;
    gap: 15px;
    padding-top: 38px;
}


.system-settings-and-benefits .content ul li{
    display: list-item;
    list-style: disc;
    font-size: 16px;
    color: #5e5e5e;
}


.system-settings-and-benefits.dark-mode {
    background-color: transparent;
}


.system-settings-and-benefits.dark-mode .content ul li {
    color: #fff;
}



/* en */

.system-settings-and-benefits.en .content ul {
    direction: ltr;
}

.system-settings-and-benefits.en .question .header {
    direction: rtl;
}