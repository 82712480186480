.vesion{
    height: 450px;
    position: relative;
}

.vesion>img{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}


.vesion .text{
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.vesion .image{
    height: 320px;
}

.vesion .image img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}


@media(max-width:950px){
    .vesion .image img {
        width: 100%;
        height: 250px;
        object-fit: contain;
    }

    .vesion .image {
        height: 162px;
    }
}