.our-team{
    margin-top: 100px;
    margin-bottom: 120px;
    overflow: hidden;
    background-color: #FDF7F3;
}

.our-team .our-team-content{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.our-team .our-team-content>img:nth-child(1){
    height: 450px;
    width: 50%;
    border-radius: 10px 0 0 10px;
    object-fit: cover;
}

.our-team .our-team-content .text{
    height: 450px;
    display: flex;
    flex-direction: column;
    width: 27%;
    align-items: flex-end;
    justify-content: center;
    position: relative;
    z-index: 2;
}





.our-team .our-team-content>img:nth-child(3){
    position: absolute;
    right: -10%;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0.2;
    z-index: 1;
}