.banks-pop-up{
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: #00000059;
    z-index: 4000;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;

}


.banks-pop-up.fade-out {
    opacity: 0;
    pointer-events: none;
}


.banks-pop-up .pop-content{
    background-color: #fff;
    width: 70%;
    height: 600px;
    border-radius: 10px;
    position: relative;
    padding: 31px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.banks-pop-up .pop-content .header 
{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.banks-pop-up .pop-content .header button{
    background-color: transparent;
    outline: none;
    border: none;
    color: #000;
    font-size: 22px;
}


.banks-pop-up .pop-content .header h1{
    font-size: 31px;
}


.banks-pop-up .pop-content table{
    direction: rtl;
    padding: 15px;
    margin: 0px;
    position: relative;
    width: 100%;
}


.banks-pop-up .pop-content table tr{
    border-bottom: 1px solid #0000002b;
}

.banks-pop-up .pop-content table th{
    font-size: 20px;
    font-weight: 600;
    color: #000;
    padding: 15px;

}



.banks-pop-up .pop-content table td{
    padding: 15px;
}



.banks-pop-up .pop-content table h2{
    font-size: 20px;
    font-weight: 600;
    color: #000;
}


.banks-pop-up .pop-content table h3{
    margin: 0;
    font-size: 17px;
    display: flex;
    flex-direction: row-reverse;
    text-align: right;
    justify-content: flex-end;
    gap: 8px;
    font-weight: 500;
    color: #000;
    align-items: center;
}



.banks-pop-up .pop-content table h3 button{
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 22px;
}







.banks-pop-up .pop-content .down-lay{
    position: relative;
    border-radius: 8px;
    height: 150px;
    width: 100%;
}


.banks-pop-up .pop-content .down-lay>img{
    width: 100%;
    height: 150px;
    object-fit: cover;
    object-position: inherit;
    position: absolute;
    inset: 0;
    border-radius: 8px;
}

.banks-pop-up .pop-content .down-lay>.overlay{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background-color: #d95d13;
    background: rgb(255, 255, 255);
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.6223739495798319) 0%, rgba(217, 93, 19, 1) 100%);
    background: rgb(217, 93, 19);
    background: linear-gradient(90deg, rgba(217, 93, 19, 0.6279761904761905) 0%, rgba(217, 93, 19, 1) 100%);
}


.banks-pop-up .pop-content .down-lay .text{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
    gap: 15px;
    justify-content: flex-end;
    height: 100%;
    padding: 22px;
}

.banks-pop-up .pop-content .down-lay .text div{
    direction: rtl;
    color: #fff;
}

.banks-pop-up .pop-content .down-lay .text div h3{
    font-size: 15px;
    color: #fff;
    font-weight: 400;
}

.banks-pop-up .pop-content .down-lay .text div h1{
    font-size: 31px;
    font-weight: 600;
    color: #fff;
}


.banks-pop-up .pop-content .down-lay .text img{
    width: 70px;
    height: 70px;
    object-fit: contain;
}


.table-scroller{
    width: 100%;
}

@media(max-width:950px){
    .banks-pop-up .pop-content {
        background-color: #fff;
        width: 99%;
        height: 600px;
        border-radius: 10px;
        position: relative;
        padding: 31px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .banks-pop-up .pop-content .down-lay .text div h1 {
        font-size: 18px;
        font-weight: 600;
        color: #fff;
    }

    .banks-pop-up .pop-content .down-lay .text div h3 {
        font-size: 13px;
        color: #fff;
        font-weight: 400;
    }

    .pop-content .table-scroller table {
        
        width: 825px;
    }

    .table-scroller {
        width: 100%;
        overflow-x: scroll;
    }

}


/* en */


.banks-pop-up.en .pop-content table {
    direction: ltr;
}

.banks-pop-up.en .pop-content .down-lay .text {

    direction: rtl;
    text-align: left;
}

.banks-pop-up.en .pop-content .down-lay .text div h3 {

    direction: ltr;
}

.banks-pop-up.en .pop-content .header {
    flex-direction: row-reverse;
}