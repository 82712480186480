
.most-services .header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}


.most-services .services-slider{
    margin-top: 100px;
    margin-bottom: 100px;
}

.most-services .services-slider .services-slide{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 15px;
}


.most-services .services-slider .services-slide .service{
    width: 33.3333%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 600px;
    padding: 21px;
    background-color: #FDF7F3;
    border-radius: 18px;
    border: 1px solid transparent;
    position:relative;
    margin-top: 50px;
    transition: 0.5s;
    top: 0;
}

.most-services .services-slider .services-slide .service:hover{
    top: -26px;
    border: 1px solid #d95d13;
}


.most-services .services-slider .services-slide .service img{
    width: 100%;
    height: 193px;
    object-fit: contain;
    margin-top: 107px;
}


.swiper-pagination-bullet-active {
    width: 40px !important;
    border-radius: 50px !important;
    height: 7px !important;
    background-color: #d95d13 !important;
}

.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    transition: 0.5s !important;
}


/* dark-mode */

.most-services.dark-mode .services-slider .services-slide .service {
    background-color: #2D2E3A;

}

@media(max-width:950px){
    .most-services .services-slider .services-slide {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        gap: 15px;
    }

    .most-services .services-slider .services-slide .service {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 600px;
        padding: 21px;
        border-radius: 18px;
        border: 1px solid transparent;
        position: relative;
        margin-top: 50px;
        transition: 0.5s;
        top: 0;
    }
    
}


/* en */


.most-services.en .header {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
}