.our-services-page-text{
    margin-bottom: 100px;
}

.our-services-page-text .text{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

