.how-to-use-text{
    margin-bottom: 120px;
}

.how-to-use-text .image{
    position: relative;
}


.how-to-use-text .image img:nth-child(2){
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
}

.how-to-use-text .text {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}


@media(max-width:950px){
    .how-to-use-text .image {
        position: relative;
        margin-bottom: 73px;
    }
}