.courses-explain{

}

.courses-explain .video{
    position: relative;
}

.courses-explain .video video{
    width: 100%;
    height: 425px;
    object-fit: cover;
    border-radius: 10px;
}

.courses-explain .video .play-btn{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.courses-explain .video .play-btn button{
    width: 70px;
    height: 70px;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    background-color: #fff;
}

.courses-explain .video .play-btn button img{
    width: 22px;
}

.courses-explain .video .play-btn span:nth-child(2){
    width: 90px;
    height: 90px;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    background-color: transparent;
    position: absolute;
    border: 1px solid #ffffff78;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.courses-explain .video .play-btn span:nth-child(3){
    width: 110px;
    height: 110px;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    background-color: transparent;
    position: absolute;
    border: 1px solid #ffffff78;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.courses-explain .text{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 416px;
    justify-content: flex-start;
}

.courses-explain .text h2{
    font-size: 16px;
    color: #d95d13;
}



/* en */

.courses-explain.en .text {
    direction: rtl;
}

.courses-explain.en {
    direction: rtl;
}

