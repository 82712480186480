.about-page-text{

}

.about-page-text .about-video{
    position: relative;
}

.about-page-text .about-video img{
    width: 100%;
    height: 425px;
    object-fit: cover;
    border-radius: 10px;
}

.about-page-text .about-video .play-btn{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.about-page-text .about-video .play-btn button{
    width: 70px;
    height: 70px;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    background-color: #fff;
}

.about-page-text .about-video .play-btn button img{
    width: 22px;
}

.about-page-text .about-video .play-btn span:nth-child(2){
    width: 90px;
    height: 90px;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    background-color: transparent;
    position: absolute;
    border: 1px solid #ffffff78;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.about-page-text .about-video .play-btn span:nth-child(3){
    width: 110px;
    height: 110px;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    background-color: transparent;
    position: absolute;
    border: 1px solid #ffffff78;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.about-page-text .image{
    height: 600px;
}


.about-page-text .image img{
    
    width: 100%;
    object-fit: cover;
    height: 100%;
}

.about-page-text .text{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.about-page-text.en {
    direction: rtl;
}