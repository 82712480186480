.section-header{
    position: relative;
}

.section-header>img:nth-child(1){
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.section-header>img:nth-child(2){
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.section-header .section-header-content{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-height: 600px;
    padding-top: 60px;
}

.section-header .section-header-content .left{
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-color: #c7784a;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.section-header .section-header-content .left.no-image{
    background-color: transparent;

}
.section-header .section-header-content .left img{
    width: 239px;
    height: 239px;
    object-fit: cover;
    border-radius: 50%;
}

.section-header .section-header-content .right{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 15px;
    width: 51%;
    text-align: right;
}

.section-header .section-header-content .right ul{
    display: flex;
    flex-direction: row-reverse;
    gap: 5px;
    align-items: center;
    padding: 0;
}



.section-header .section-header-content .right ul li a{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 6px;
    font-size: 15px;
    color : #000;

}





.section-header .section-header-content .right ul li a.active{
    color: #d95d14;
}



.section-header .section-header-content .right h1{
    font-size: 50px;
    font-weight: 700;
    color: #181923;
    direction: rtl;
}



/* dark-mode */
.section-header.dark-mode .section-header-content .right ul li a {
    color: #fff;
}

.section-header.dark-mode .section-header-content .right h1 {
    color: #fff;

}

@media(max-width:950px){
    .section-header .section-header-content .left img {
        width: 121px;
        height: 121px;
        object-fit: cover;
        border-radius: 50%;
    }


    .section-header .section-header-content .right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 15px;
        width: 100%;
        text-align: right;
    }

    .section-header .section-header-content .right h1 {
        font-size: 30px;
        font-weight: 700;
        color: #181923;
        direction: rtl;
    }

    .section-header .section-header-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        min-height: 328px;
        padding-top: 119px;
        width: 100%;
        padding-bottom: 30px;
        gap: 39px;
    }


    .section-header .section-header-content .left {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
}


/* en */

.section-header.en .section-header-content {
    direction: rtl;
}

.section-header.en .section-header-content .right {
    text-align: left;
}

.section-header.en .section-header-content .right h1 {
    direction: ltr;
}
