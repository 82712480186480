
.devices-benefits-text .text{
    margin-bottom: 150px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    margin-top: 0;
}


.devices-benefits-text .text ul{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    gap: 15px;
    margin-top: 50px !important;
}

.devices-benefits-text .text ul li{
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: right;
    direction: rtl;
    font-size: 15px;
}

.devices-benefits-text .text ul li img{
    height: 27px;
}


.devices-benefits-text .image{
    margin-bottom: 150px;
}


.devices-benefits-text .image img{
    margin-top: 150px;
}