
.page-side-nav ul{
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    text-align: center;
    text-align: right;
}

.page-side-nav ul li{
    width: 100%;
}

.page-side-nav ul li a{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    text-align: center;
    height: 40px;
    background-color: transparent;
    border: none;
    outline: none;
    color: #000;
    font-size: 16px;
    font-weight: 400;
    border-radius: 9px;
    text-align: right;
    padding-right: 20px;
}


.page-side-nav ul li a.active{
    background-color: #d95d13;
    color: #fff;
}

.faqs-page .search{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    position: relative;
    margin-left: 50px;
}


.faqs-page .search button{
    position: absolute;
    left: 0;
}


.faqs-page .search input{
    width: 100%;
    direction: rtl;
    height: 45px;
    border-radius: 50px;
    border: 1px solid #0000003b;
    padding-right: 50px;
    border: 2px solid #00000008;
    box-shadow: 0px 21.43px 35.72px 0px #0000000F;
    padding-left: 180px;
}


.faqs-page .search img{
    width: 24px;
    position: absolute;
    right: 11px;
    top: 50%;
    transform: translateY(-50%);
}



.faqs{
    margin-left: 50px;
}

.faqs .header h1{
    text-align: right;
}

.page-side-nav.dark-mode ul li a {

    color: #fff;
}
.faqs-page.dark-mode .search input {
    background-color: #2D2E3A;
    color: #fff;
}

@media(max-width:950px){
    .faqs-page .search {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        position: relative;
        margin-left: 0;
    }
    .page-side-nav.dark-mode {
        margin-bottom: 50px;
    }
    
}