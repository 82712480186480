.our-clients{
    margin-bottom: 150px;
}

.our-clients .clients{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 100px;
}

.our-clients .clients .client{
    width: 25%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    height: 200px;
    border: 1px solid #00000021;
    transition: 0.5s;
}
.our-clients .clients .client:hover{
    border: 1px solid #000;

}


.our-clients .clients .client img{
    object-fit: contain;
    width: 100px;
    height: 100px;
}


.our-clients .clients .client:nth-child(1){
    border-top: 0;
    border-left: 0;
}

.our-clients .clients .client:nth-child(2){
    border-top: 0;
}
.our-clients .clients .client:nth-child(3){
    border-top: 0;
}

.our-clients .clients .client:nth-child(4){
    border-top: 0;
    border-right: 0;
}

.our-clients .clients .client:nth-child(5),
.our-clients .clients .client:nth-child(9),
.our-clients .clients .client:nth-child(13),
.our-clients .clients .client:nth-child(17),
.our-clients .clients .client:nth-child(21){
    border-left: 0;
    
}

.our-clients .clients .client:nth-child(25){
    border-left: 0;
    border-bottom: 0;
}


.our-clients .clients .client:nth-child(26){
    border-bottom: 0;
}

.our-clients .clients .client:nth-child(27){
    border-bottom: 0;

}

.our-clients .clients .client:nth-child(28){
    border-bottom: 0;
    border-right: 0;
}

.our-clients .clients .client:nth-child(8),
.our-clients .clients .client:nth-child(12),
.our-clients .clients .client:nth-child(16),
.our-clients .clients .client:nth-child(20),
.our-clients .clients .client:nth-child(24){
    border-right: 0;
    
}



.our-clients.dark-mode .clients .client {
    background-color: transparent;
    border: 1px solid #ffffff12;

}

.our-clients .clients .client:hover {
    border: 1px solid #fff;
}

@media(max-width:950px){
    .our-clients .clients .client {
        width: 50%;
        
    }
}