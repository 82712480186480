.expreince{
    position: relative;
    padding-bottom: 134px;
}

.expreince>img{
    position: absolute;
    inset: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.2;
}


.expreince .header{
    padding-top: 60px;
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}


.expreince .header h1{
    text-align: center;
}

.expreince .header p{
    
}

.expreince .slider {
    position: relative;
}
.expreince-slider-btns{
    margin-top: 29px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    justify-content: center;
    gap: 30px;
    position: absolute;
    width: 107%;
    display: flex;
    justify-content: space-between;
    top: 43%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.expreince-slider-btns button{
    width: 50px;
    height: 50px;
    background-color: #2d7bbb;
    color: #fff;
    border-radius: 50%;
    border: none;
    outline: none;
    font-size: 22px;
}


.expreince .swiper-slide{
    width: fit-content !important;
}


.expreince .exp{
    width: 146px;
    height: 418px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: 0.5s;

}

.expreince .swiper-slide-active .exp{

}

.expreince .swiper-slide-prev .exp{

}

.expreince .swiper-slide-next .exp{

}

.expreince .exp .circle{
    width: 70px;
    height: 70px;
    border: 1px solid #d95d13;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #Fff;
    border-radius: 50%;
    position: relative;
    z-index: 1;
    transition: 0.5s;

}


.expreince .swiper-slide-active .exp  .circle{
    width: 150px;
    height: 150px;
    background-color: #d95d13;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #Fff;
    border-radius: 50%;
    transition: 0.5s;

} 

.expreince .swiper-slide-prev .exp  .circle{
    width: 120px;
    height: 120px;
    transition: 0.5s;

}

.expreince .swiper-slide-next  .exp .circle{
    width: 120px;
    height: 120px;
    transition: 0.5s;

}





.expreince .exp .circle h1{
    display: none;
    transition: 0.5s;

}

.expreince .swiper-slide-active .exp .circle h1{
    display: block;
    font-size: 40px;
    font-weight: 600;
    color: #fff;
    transition: 0.5s;

}

.expreince .swiper-slide-prev .exp .circle h1{
    color: #d95d13;
    display: block;
    font-weight: 400;
    font-size: 37px;
    transition: 0.5s;

}


.expreince .swiper-slide-next .exp .circle h1{
    color: #d95d13;
    display: block;
    font-weight: 400;
    font-size: 37px;
    transition: 0.5s;

}



.expreince .exp .info{
    display: none;
}

.expreince .swiper-slide-active .exp  .info{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    width: 600px;
    transition: 0.5s;
}

.expreince .swiper-slide-active .exp .info h2{
    text-align: center;
    font-size: 27px;
    color: #0864AF;
    font-weight: 700;
    width: 100%;
    direction: rtl;
    transition: 0.5s;

}

.expreince .swiper-slide-active .exp .info h3{
    font-size: 20px;
    text-align: center;
    margin-top: 15px;
    font-weight: 500;
    transition: 0.5s;

}


/* dark-mode */

.expreince.dark-mode .swiper-slide-active .exp .info h3 {
    color: #fff;
}

@media(max-width:950px){
    .expreince .exp {
        width: 146px;
        height: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        transition: 0.5s;
    }

    .expreince  .exp .circle {
        
        margin-bottom: 50px;
    }
}