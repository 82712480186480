.pop-up{
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: #00000059;
    z-index: 4000;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

}


.pop-up.fade-out {
    opacity: 0;
    pointer-events: none;
}


.pop-up .pop-content{
    background-color: #fff;
    width: 500px;
    height: 600px;
    border-radius: 10px;
    position: relative;
}

.pop-up .pop-content>button{
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    outline: none;
    border: none;
    color: #fff;
    font-size: 15px;
}

.pop-up .pop-content>img{
    width: 100%;
    height: 59%;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
}

.pop-up .pop-content .text{
    padding: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
    height: 38%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.pop-up .pop-content .text h1{
    font-size: 22px;
    text-align: center;
    font-weight: 500;
    color: #000;
    font-weight: 600;
    line-height: 30px;
}

.pop-up .pop-content .text h1 span{
    color: #d95d13;
}

.pop-up .pop-content .text .inputs {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
}


.pop-up .pop-content .text .inputs input{
    height: 45px;
    width: 100%;
    border-radius: 50px;
    border: none;
    outline: none;
    text-align: right;
    padding-right: 15px;
    padding-left: 184px;
    background-color: #f2f2f2;
}


.pop-up .pop-content .text .inputs button{
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}