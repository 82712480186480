.our-work{
    margin-bottom: 60px;
}

.our-work .header{
    margin-top: 60px;
    margin-bottom: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}



.our-work .slider .clients{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}

.our-work .slider .clients img{
    width: calc(10% - 20px);
    height: 80px;
    margin-bottom: 55px;
    object-fit: contain;
    margin-right: 20px;
    filter: grayscale(1);
}


@media(max-width:950px){
    .our-work .header {
        margin-top: 60px;
        margin-bottom: 100px;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-between;
        gap: 30px;
    }

    .our-work .slider .clients img {
        width: calc(33% - 20px);
        height: 80px;
        margin-bottom: 55px;
        object-fit: contain;
        margin-right: 20px;
        filter: grayscale(1);
    }
}


/* en */


.our-work.en .header {
    direction: rtl;
}