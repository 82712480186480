.api-files{
    margin-bottom: 108px;
    margin-top: 120px;
}


.api-files .section-header-ele p{
    display: block;
    width: 49%;
    text-align: center;
}

.api-files .files{
    padding: 20px;
}

.api-files .files ul{
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 14px;
}

.api-files .files ul li{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.api-files .files ul li a{ 
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    font-size: 15px;
    color: #d95d13;
}

.api-files .files ul li a img{ 
    width: 18px;
}

.api-files .files ul li h4{ 
    direction: rtl;
    display: flex;
    flex-direction: row-reverse;
    gap: 9px;
    align-items: center;
    font-size: 16px;
}

.api-files .files ul li h4 span{ 
    font-size: 15px;
    color: #a2a2a2;
    direction: ltr;
}


.api-files .image{ 
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.api-files .image img{ 
    width: 100%;
    height: 204px;
    object-fit: contain;
}

.api-files.dark-mode .files ul li h4 {
    color: #fff;
}



@media(max-width:950px){
    .api-files.dark-mode .files ul li h4 {
        color: #fff;
        text-align: right;
        direction: rtl;
    }
}