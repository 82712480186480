.exp-lesson{
    margin-bottom: 120px;
}


.exp-lesson .explain-content h2{
    text-align: right;
    font-weight: 600;
    color: #000;
    font-size: 25px;
}


.exp-lesson .explain-content .explain{
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #d95d134f;
    margin-bottom: 15px;
    margin-top: 25px;
    background-color: #d95d130a;
}


.exp-lesson .explain-content .explain .header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.exp-lesson .explain-content .explain .header button{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: #d95d13;
    font-size: 18px;
    border: none;
    outline: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: 1px solid #d95d13;
    transition: 0.5s;
}

.exp-lesson .explain-content .explain .header button.active{
    background-color: #d95d13;
    color: #fff;
    
}

.exp-lesson .explain-content .explain .header button.active{
    background-color: #d95d13;
    color: #fff;

}


.exp-lesson .explain-content .explain .header div h3{
    font-size: 15px;
}


.exp-lesson .explain-content .explain .header div p{
    display: flex;
    margin: 0;
    margin-top: 7px;
    align-items: center;
    gap: 17px;
    font-size: 14px;
    direction: rtl;
}



.exp-lesson .explain-content .explain .content{
    display: none;
}

.exp-lesson .explain-content .explain .content.active{
    display: block;
}


.exp-lesson .explain-content .explain .content ul{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 5px;
    margin-top: 15px !important;
    padding: 0;
}

.exp-lesson .explain-content .explain .content ul li{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid #00000024;
    width: 100%;
    justify-content: flex-end;
}

.exp-lesson .explain-content .explain .content ul li h4{
    direction: rtl;
    font-size: 15px;
    text-align: right;
    margin: 0;
    color: #d95d13;
}

.exp-lesson .explain-content .explain .content ul li img{
    width: 27px;
}



.exp-lesson .video iframe{
    border-radius: 10px;
}

.exp-lesson.dark-mode .explain-content h2 {
    color: #fff;
}



/* en */


.exp-lesson.en .explain-content .explain .header {
    direction: rtl;
}

.exp-lesson.en .explain-content .explain .header div p {

    direction: ltr;
}

.exp-lesson.en .explain-content .explain .content ul li {
    display: flex;
    text-align: left;
    direction: ltr;
}

.exp-lesson.en .explain-content .explain .content ul li h4 {
    text-align: left;
}