.dashboard{
    margin-top: 80px;
    margin-left: 21%;
    transition: 0.3s;
}

.dashboard.active{
    margin-left: 6%;
}

.dashboard .stats-content{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}


.dashboard .stats-content .stat{
    width: 250px;
    min-height: 100px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    padding: 13px;
    border-radius: 17px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.19);
}

.dashboard .new-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 31px;
    background-color: #000;
    border-radius: 5px;
    color: #fff;
    font-size: 14px;
    margin-bottom: 15px;
    padding: 5px;
}



.dashboard .stats-content .stat i{
    font-size: 50px;
}

.dashboard .stats-content .stat .info{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.dashboard .stats-content .stat .info h1{
    font-size: 33px;
}


.dashboard .stats-content .stat .info h2{
    font-size: 18px;
    font-weight: 400;
}




@media(max-width:950px){
    .dashboard .stats-content {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        gap: 22px;
    }
}