nav{
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1000;
}

nav .nav-content{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 15px;
}

nav .nav-content .left{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 40px;
}

nav .nav-content .left a{
    font-size: 15px;
    font-weight: 400;
    color: #667080;

}

nav .nav-content .left a i{
    font-size: 22px;

}

nav .nav-content .left a img{
    width: 30px;
    height: 23px;
    object-fit: contain;
}

nav .nav-content .right{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 60px;
}

nav .nav-content .right .nav-elements ul{
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

nav .nav-content .right .nav-elements ul li a{
    font-size: 15px;
    font-weight: 400;
    color: #667080;
}

nav .nav-content .right .nav-elements ul li a.active{
    color: #D95D13;
}



nav .nav-content .right .logo img{
    width: 130px;
}



#open-nav{
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 31px;
    display: none;
}

#close-nav{
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 31px;
    display: none;
}



/* dark-mode  */

nav.dark-mode .nav-content .right .nav-elements ul li a {
    color: #fff;
}
nav.dark-mode .nav-content .right .nav-elements ul li a.active{
    color: #D95D13;

}

nav.dark-mode .nav-content .left a {
    font-size: 15px;
    font-weight: 400;
    color: #fff;
}


nav.dark-mode #open-nav {
    color: #fff;
}

nav.dark-mode #close-nav {
    color: #fff;

}
/* screens */

@media(max-width:950px){

    
    #open-nav{
        display: block;
    }

    #close-nav{
        display: block;
    }

    nav .nav-content .right .nav-elements {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 60px;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        justify-content: center;
        background-color: #fff;
    }

    nav .nav-content .right .nav-elements ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 40px;
        padding: 0;
        justify-content: center;
        align-items: center;
    }

    
    nav .nav-content .right .nav-elements{
        display: none;
    }


    nav .nav-content .left {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 3px;
        gap: 19px;
        gap: 7px;
    }
    
    nav .nav-content .right .logo img {
        width: 99px;
    }

    nav.dark-mode .nav-content .right .nav-elements {
        
        background-color: #2D2E3A;
    
}

}




/* ENGLISH */

nav.en .nav-content {
    direction: rtl;
}