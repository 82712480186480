.user-login-pages{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.user-login-pages .left{
    width: 50%;
    height: 100vh;
    position: relative;
    background-color: #f7dccd;
    background: rgb(247, 220, 205);
    background: linear-gradient(167deg, rgba(247, 220, 205, 1) 0%, rgba(255, 255, 255, 1) 100%);
}

.user-login-pages .left>img{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.user-login-pages .left .user-side-page{
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    text-align: right;
    justify-content: center;
    padding-top: 50px;
    padding: 50px;
    padding: 50px;
    position: relative;
    z-index: 1;
}


.user-login-pages .left .user-side-page .content{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
    width: 100%;
    gap: 14px;
}


.user-login-pages .left .user-side-page .content img{
    width: 100px;
    height: 120px;
    object-fit: contain;
}


.user-login-pages .left .user-side-page .content>div{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-top: 30px;
}


.user-login-pages .left .user-side-page .content div>a:nth-child(1){
    font-size: 15px;
    font-weight: 500;
    text-decoration: underline !important;
}



.user-login-pages .right{
    width: 50%;
    height: 100vh;
}

.user-login-pages .right .form-element{
    height: calc(100vh - 100px);
    padding: 50px;
    padding-top: 0;
}

.user-login-pages .right .form-element .header{
    margin-bottom: 0;
    align-items: flex-end;
    margin-bottom: 50px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    align-items: center;
    justify-content: space-between;
}


.user-login-pages .right .form-element .header h4{
    text-align: right;
    direction: rtl;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
    font-size: 14px;
    color: #858585;
    margin-top: 23px;
}


.user-login-pages .right .form-element .header div p {
    text-align: center;
    font-size: 17px;
    direction: rtl;
    margin-top: 15px;
    color: #000000a6;
    direction: rtl;
    text-align: right;

}





.user-login-pages .right .form-element .header a{
    font-weight: 700;
    font-size: 16px;
    color: #d95d13;
}



.user-login-pages .right .form-element .login-google{
    
}


.user-login-pages .right .form-element .login-google button{
    width: 100%;
    height: 45px;
    border: 1px solid #0000001f;
    border-radius: 4px;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 15px;
    font-weight: 500;
    font-size: 15px;
}


.user-login-pages .right .form-element .login-google button img{
    width: 23px;
}


.user-login-pages .right .form-element .or{
    margin-top: 15px;
    margin-bottom: 15px;
    position: relative;
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.user-login-pages .right .form-element .or span{
    width: 100%;
    height: 1px;
    background-color: #0000001f;
}

.user-login-pages .right .form-element .or h4{
    font-size: 18px;
    font-weight: 600;
    color: #000;
    margin: 0;
    background-color: #fff;
    width: 33px;
    height: 30px;
    padding: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}


.user-login-pages .right .form-element form{
    align-items: flex-end;
}

.user-login-pages .right .form-element .input button{
    position: absolute;
    left: 10px;
    top: 55%;
    background-color: transparent;
    outline: none;
    border: none;
}

.user-login-pages .right .form-element form .submit-sec{
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}



.user-login-pages .right .form-element form .submit-sec a:not(.link-btn-midcolor){
    font-size: 15px;
    text-decoration: underline !important;
    color: #0864af;
    font-weight: 500;
}

.user-login-pages .right .form-element form .submit-sec a.link-btn-midcolor{

}

.user-login-pages.dark-mode .left {
    background-color: transparent;
    background: transparent;

}

.user-login-pages.dark-mode .right .form-element .header div p {
    color: #fff;

}

.user-login-pages.dark-mode .right .form-element .login-google button {
    color: #fff;
}

.user-login-pages.dark-mode .right .form-element .or h4 {

    color: #fff;
    background-color: transparent;

}

@media(max-width:950px){
    .user-login-pages {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
        justify-content: space-between;
    }

    .user-login-pages .left {
        width: 100%;
        margin-top: 0;
    }

    .user-login-pages .left .user-side-page {
        height: 80vh;
    }
    
    .user-login-pages .right {
        width: 100%;
    }

    .form-element .header h1 {
        font-size: 22px;
        font-weight: 800;
        text-align: right;
    }

    .user-login-pages .right .form-element form .submit-sec {
        margin-top: 30px;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 62px;
    }

    .user-login-pages .right .form-element {
        height: calc(100vh - 100px);
        padding: 10px;
        padding-top: 0;
    }

    .user-login-pages .right {
        height: 121vh;
    }

    .user-login-pages .left .user-side-page .content>div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        margin-top: 30px;
    }

    .form-element form .inputs {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 20px;
    }

    .form-element form .inputs .input>div.splitter {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 122px;
        align-items: center;
        justify-content: center;
        gap: 17px;
    }

    .user-login-pages .right {
        height: 147vh;
    }

    .user-login-pages .right.login{
        height: 100vh;
    }

    .user-login-pages .right {
        min-height: 78vh;
        height: inherit;
        margin-bottom: 201px;
    }
}