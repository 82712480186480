
.career .image img{
    width: 100%;
    object-fit: contain;
    height: 700px;
}

.form-element{
    margin-top: 100px;
    margin-bottom: 100px;
}

.form-element .header{
    margin-top: 100px;
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.form-element .header h1{
    font-size: 40px;
    font-weight: 800;
    color: #000;
    text-align: right;
}

.form-element .header p{
    text-align: center;
    font-size: 17px;
    direction: rtl;
    margin-top: 15px;
    color: #000000a6;
}

.form-element form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding-bottom: 50px;

}


.form-element form .inputs{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
}


.form-element form .inputs .input{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 9px;
    position: relative;
}
.form-element form .inputs .input>div.splitter{
    display: flex;
    flex-direction: row;
    width: 100%;
}


.form-element form .inputs .input label{
    font-size: 16px;
    font-weight: 500;
    color: #000;
}

.form-element form .inputs .input input{
    width: 100%;
    height: 44px;
    border-radius: 15px;
    border: 1px solid #00000030;
    direction: rtl;
    padding-right: 11px;
    transition: 0.5s;
    outline: 1px solid transparent;

}

.form-element form .inputs .input input:focus{
    outline: 1px solid #d95d13;

}

.form-element form .inputs .input select{
    width: 100%;
    height: 44px;
    border-radius: 15px;
    border: 1px solid #00000030;
    direction: rtl;
    padding-right: 11px;
    outline: 1px solid transparent;

}

.form-element form .inputs .input select:focus{
    outline: 1px solid #d95d13;

}

.form-element form .inputs .input textarea{
    width: 100%;
    height: 150px;
    border-radius: 15px;
    border: 1px solid #00000030;
    direction: rtl;
    padding-right: 11px;
    outline: 1px solid transparent;

}

.form-element form .inputs .input textarea:focus{
    outline: 1px solid #d95d13;

}


.form-element form>button{
    margin-top: 60px;
}





.form-element .upload-input{
    margin-top: 52px;
    width: 100%;
    height: 150px;
}


.form-element .upload-input .drag-here{
    height: 150px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-color: #d95d13 !important;
    background-color: #fdf7f3;
}


.form-element .upload-input .drag-here h1{
    font-size: 25px;
    font-weight: 500;
    color: #000;
}


.form-element .upload-input .drag-here h2{
    font-size: 14px;
    direction: rtl;
    color: #a7a7a7;
}


.css-khl8b7-container {
    position: relative;
    box-sizing: border-box;
    flex: 1 1;
    border-radius: 15px;
    width: 100%;
    height: 45px;
    position: relative;
}

.css-13cymwt-control {
    align-items: center;
    cursor: default;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    transition: all 100ms;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    height: 45px;
    border-radius: 15px;
    min-width: 234px;
    border-radius: 15px !important;
}

.css-hlgwow {

    height: 100% !important;
}

.css-qbdosj-Input {
    visibility: visible;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    display: inline-grid;
    grid-area: 1 / 1 / 2 / 3;
    grid-template-columns: 0 min-content;
    margin: 2px;
    padding-bottom: 2px;
    padding-top: 2px;
    color: hsl(0, 0%, 20%);
    box-sizing: border-box;
    display: none !important;
}

.css-1hb7zxy-IndicatorsContainer {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    box-sizing: border-box;
}

.form-element.dark-mode .header h1 {
    color: #fff;
}


.form-element.dark-mode .header p {
    color: #fff;
}
.form-element.dark-mode form .inputs .input label {

    color: #fff;
}

.form-element.dark-mode form .inputs .input input {
    background-color: #2D2E3A;
    color: #fff;

}

.form-element.dark-mode form .inputs .input textarea {
    background-color: #2D2E3A;
    color: #fff;

}

.form-element.dark-mode form .inputs .input select {
    background-color: #2D2E3A;
    color: #fff;
}
.form-element.dark-mode .upload-input .drag-here {
    
    background-color: #2D2E3A;
    color: #fff;

}

.form-element.dark-mode .upload-input .drag-here h1 {
    color: #fff;
}

.dark-mode .css-13cymwt-control {
    background-color: #2D2E3A !important;
    color: #fff !important;
    
}

.dark-mode .css-13cymwt-control {
    color: #fff !important;
}