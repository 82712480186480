.rates{
    margin-top: 150px;
    margin-bottom: 150px;
}

.rates .rates-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    justify-content: space-between;
}

.rates .rates-slider{
    margin-top: 120px;
}


.rates .rates-slider .swiper-slide{
    width: fit-content !important;
}


.rates .rates-slider .swiper-slide-active .rate{
    width: 350px;
    margin-bottom: 70px;
    height: 300px;
    background-color: #d95d13;
    border-radius: 10px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    filter: none;
    opacity: 1;
}


.rates .rates-slider .swiper-slide-active .rate .rate-content{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 16px;
}

.rates .rates-slider .swiper-slide-active .rate .rate-content img{
    width: 54px;
}


.rates .rates-slider .swiper-slide-active .rate .rate-content p{
    text-align: right;
    direction: rtl;
    color: #fff;
    line-height: 22px;
}

.rates .rates-slider .swiper-slide-active .rate .client-info{
    text-align: right;
}

.rates .rates-slider .swiper-slide-active .rate .client-info h2{
    font-size: 13px;
    color: #fff;
    font-weight: 600;
}


.rates .rates-slider .swiper-slide-active .rate .client-info h3{
    font-size: 13px;
    color: #fff;
    font-weight: 300;
}












.rates .rates-slider .rate{
    width: 350px;
    margin-bottom: 70px;
    height: 300px;
    background-color: #d95d13;
    border-radius: 10px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    filter: invert(1);
    opacity: 0.2;
    background-color: transparent;
    color: #000;
    transition: 0.5s;
}


.rates .rates-slider .rate .rate-content{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 16px;
}

.rates .rates-slider .rate .rate-content img{
    width: 54px;
}


.rates .rates-slider .rate .rate-content p{
    text-align: right;
    direction: rtl;
    color: #fff;
    line-height: 22px;
}

.rates .rates-slider .rate .client-info{
    text-align: right;
}

.rates .rates-slider .rate .client-info h2{
    font-size: 13px;
    color: #fff;
    font-weight: 600;
}


.rates .rates-slider .rate .client-info h3{
    font-size: 13px;
    color: #fff;
    font-weight: 300;
}


.slider-btns{
    margin-top: 29px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    justify-content: center;
    gap: 30px;
}

.slider-btns button{
    width: 50px;
    height: 50px;
    background-color: #2d7bbb;
    color: #fff;
    border-radius: 50%;
    border: none;
    outline: none;
    font-size: 22px;
}


@media(max-width:950px){
    .rates .rates-header {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-around;
        justify-content: space-between;
        gap: 50px;
    }
}

/* en */


.rates.en .rates-header {
    flex-direction: row-reverse;
}

.rates.en .rates-slider .swiper-slide .rate {
    direction: rtl;
}

.rates.en .rates-slider .swiper-slide .rate .rate-content p {
    text-align: left;
    direction: ltr;

}