

.who-gets-our-benefits .consumers {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
}

.who-gets-our-benefits .consumers .consumer{
    width: 15%;
    height: 450px;
    position: relative;
    border-radius: 8px;
    transition: 0.5s;
}

.who-gets-our-benefits .consumers .consumer.active{
    width: 70%;
}

.who-gets-our-benefits .consumers .consumer img{
    width: 100%;
    height: 450px;
    object-fit: cover;
    position: absolute;
    inset: 0;
    border-radius: 5px;
}

.who-gets-our-benefits .consumers .consumer .text{
    position: relative;
    bottom: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding: 50px;
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    transition: 0.5s;
    border-radius: 8px;
}

.who-gets-our-benefits .consumers .consumer.active .text{
    align-items: flex-end;
}

.who-gets-our-benefits .consumers .consumer .text img{
    width: 50px;
    height: 50px;
    position: relative;
}

.who-gets-our-benefits .consumers .consumer .text h2{
    font-size: 25px;
    font-weight: 600;
    color: #fff;
    display: none;
    transition: 0.5s;
}

.who-gets-our-benefits .consumers .consumer .text p{
    display: none;
    color: #fff;
    font-size: 14px;
    direction: rtl;
    transition: 0.5s;
    display: none;
}


.who-gets-our-benefits .consumers .consumer.active .text img{
    width: 50px;
    height: 50px;
    position: relative;
}

.who-gets-our-benefits .consumers .consumer.active .text h2{
    display: block;
}

.who-gets-our-benefits .consumers .consumer.active .text p{
    display: block;

}



@media(max-width:950px){
    .who-gets-our-benefits .consumers {
        flex-direction: column;
        
    }

    .who-gets-our-benefits .consumers .consumer {
        width: 100%;
        
    }
    .who-gets-our-benefits .consumers .consumer .text{
        text-align: right;
    }

    .who-gets-our-benefits .consumers .consumer .text h2 {
        display: block !important;
    }
    .who-gets-our-benefits .consumers .consumer .text {
        padding: 20px;
        
    }
    .who-gets-our-benefits .consumers .consumer .text p {
        display: block;
    }

    .who-gets-our-benefits .consumers .consumer.active {
        width: 100%;
        text-align: right;

    }

    .who-gets-our-benefits .consumers .consumer .text h2 {
        text-align: right;

    }
}


.who-gets-our-benefits.en .consumers .consumer .text {
    direction: rtl;
    text-align: left;
}

.who-gets-our-benefits.en .consumers .consumer .text p {
    direction: ltr;
}