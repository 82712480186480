
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Reem+Kufi:wght@400..700&display=swap');


body{
  background-color: #fff;
  scroll-behavior: smooth;

  

}

body.dark-mode{
  background-color: #181923 !important;
  scroll-behavior: smooth;

  

}


*{
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  font-family: "Tajawal", sans-serif;

  
}


/* font-family: 'IBM Plex Sans Arabic', sans-serif; */

a{
  text-decoration: none !important;
  cursor: pointer;
  transition: 0.5s;

}

a:hover:not(.link-btn-midcolor){
  color: #D95D13 !important;

}

ul{
  list-style: none !important;
  margin: 0 !important;
}


.hide{
  display: none !important;
}




.lang a{
  position: absolute;
  z-index: 5;
  left: 15px;
  top: 34px;
  color: #fff;
  font-size: 21px;
}


p{
  text-transform: math-auto;
}


/* General */

.link-btn-nocolor{
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  height: 45px;
  border-radius: 50px;
  color: #181923;
  font-size: 15px;
  text-align: center;
  font-weight: 500;
  gap: 6px;
  min-width:180px;
}

.link-btn-midcolor{
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  height: 45px;
  background: transparent;
  border-radius: 50px;
  color: #D95D13;
  font-size: 15px;
  text-align: center;
  min-width: 170px;
  border: 1px solid #D95D13;
  color: #D95D13;
  cursor: pointer;
  transition: 0.5s;
  direction: rtl;
}

.link-btn-midcolor:hover{
  background: #D95D13;
  color: #fff;

}



.link-btn-color{
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  height: 45px;
  background: #D95D13;
  border-radius: 50px;
  color: #fff;
  font-size: 15px;
  text-align: center;
  min-width:180px;
  border: 1px solid #D95D13;
  transition: 0.5s;



}

.link-btn-color:hover{
  border: 1px solid #D95D13 !important;
  color: #D95D13;
  background: transparent;

}



.section-headline{
  text-align: right;
  font-size: 50px;
  color: #0864AF;
  font-weight: 700;
  width:100%;
  direction: rtl;

}



.section-text{
  text-align: right;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 16px;
  direction: rtl;
  color: #181923;

}


.section-sec-headline{
  font-size: 22px;
  font-weight: 700;
  color: #000;
  margin-bottom: 0;
  width:100%;
  text-align:right;

}


.section-image{
  width: 100%;
  height: 500px;
  object-fit: contain;
}

.section-header-ele{
  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content: center;
  padding-top: 60px;
  padding-bottom: 60px;
  text-align:center;

}

.section-header-ele h1{
  text-align:center;
  
}


.ecp-image{
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  z-index: -1;

}


/* dark-mode */

.section-headline.dark-mode {
  color: #fff;

}

.section-text.dark-mode {
  color: #fff;
}
.section-sec-headline.dark-mode {
  color: #fff;
}

.section-sec-headline.dark-mode  {

  color: #fff;

}

@media(max-width:950px){
  
.section-image {
  width: 100%;
  height: 350px;
  object-fit: contain;
}

.section-headline {
  text-align: right;
  font-size: 26px;
  font-weight: 700;
  width: 100%;
  direction: rtl;
}

.link-btn-midcolor {
  padding-left: 20px;
  padding-right: 20px;
  min-width: 150px;
}


.link-btn-color {
  padding-left: 20px;
  padding-right: 20px;
  min-width: 150px;
}
}


/* en */


.en .section-headline {
  text-align: left;
  direction: ltr;
}

.en .section-text {
  text-align: left;
  direction: ltr;
}


.en .section-sec-headline {
  text-align: left;
}

.en .section-header-ele h1 {
  text-align: center;
}

.dark-mode .section-sec-headline {
  color: #fff;
}

.dark-mode .section-text {
  color: #fff;
}