.devices-benefits{
    margin-bottom: 120px;
}

.devices-benefits img{
    width: 100%;
    height: 590px;
    object-fit: contain;
}

.devices-benefits.dark-mode img {
    width: 100%;
    height: 590px;
    object-fit: contain;
    filter: invert(1);
}

@media(max-width:950px){
    .devices-benefits.dark-mode img {
        height: 235px;
        
    }



}


/* en */
.devices-benefits-text.en .text ul li {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    text-align: left;
    direction: rtl;
    font-size: 15px;
}