.device-pop-up{
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: #00000059;
    z-index: 4000;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.device-pop-up.fade-out {
    opacity: 0;
    pointer-events: none;
}


.device-pop-up .pop-content{
    background-color: #fff;
    width: 65%;
    height: 703px;
    border-radius: 10px;
    position: relative;
    padding: 31px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.device-pop-up .pop-content .header 
{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.device-pop-up .pop-content .header button{
    background-color: transparent;
    outline: none;
    border: none;
    color: #000;
    font-size: 22px;
}


.device-pop-up .pop-content .header h1{
    font-size: 31px;
}

.device-pop-up .device-info{
    width: 100%;
}

.device-pop-up .device-info h2{
    text-align: right;
    direction: rtl;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    font-size: 20px;
    font-weight: 400;
    color: #868686;
    gap: 7px;
    align-items: center;
    font-weight: 600;
    color: #e37913;
}

.device-pop-up .device-info h2 span{
    font-size: 15px;
    font-weight: 400;
    color: #868686;

}



.device-pop-up .device-info p{
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    text-align: right;
    margin-bottom: 20px;
}


.device-pop-up .device-info .info{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.device-pop-up .device-info .info ul{
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
    font-size: 13px;
    font-weight: 500;
}

.device-pop-up .device-info .info ul li {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    direction: rtl;
}


.device-pop-up .device-info .info ul li img{
    width: 18px;
    height: 18px;
    padding: 0;
}

.device-pop-up .device-info .info a{
    margin-top: 32px;
}

.device-pop-up .device-info .info>img{
    width: 264px;
    padding-left: 50px;
    height: 100%;
    object-fit: contain;
}




.device-pop-up .pop-plans{
    width: 100%;
}

.device-pop-up .pop-plans>h3{
    font-size: 20px;
}


.device-pop-up .plan{
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    width: 100%;
    border-radius: 8px;
    background-color: #fdf7f3;
    min-height: 247px;
}


.device-pop-up .plan .top{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0;
    justify-content: space-between;
    padding-bottom: 15px;
    border-bottom: 1px solid #00000021;
    margin-bottom: 15px;
}


.device-pop-up .plan .top img{
    width: 65px;
    height: 65px;
    object-fit: contain;
}

.device-pop-up .plan .top h1{
    font-size: 40px;
    font-weight: 900;
    color: #000;
    display: inline-block;
    margin: 0;
}
.device-pop-up .plan .top h1 span{
    font-size: 27px;
    font-weight: 400;
}

.device-pop-up .plan .top h2{
    direction: rtl;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 9px;
    font-size: 20px;
    color: #d95d13;
    font-weight: 500;
    font-weight: 800;
}

.device-pop-up .plan .top h2 span{
    font-size: 14px;
    color: #5d5c61;
    font-weight: 500;
}

.device-pop-up .plan .top h3{
    font-size: 16px;
    color: #000;
}


.device-pop-up .plan .top>div {
    display: flex;
    flex-direction: row-reverse;
    gap: 15px;
    align-items: center;
    margin-bottom: 16px;
}