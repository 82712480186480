.landing{
    position: relative;
    margin-bottom: 100px;
}

.landing>img{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}


.landing .landing-text{
    padding-top: 79px;
    min-height: 678px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.landing .landing-text>img:nth-child(1){
    position: absolute;
    left: 21%;
    top: 55%;
    transform: translate(-50%, -50%);
    width: 79px;
}

.landing .landing-text>img:nth-child(2){
    position: absolute;
    top: 0;
    right: 0;
    position: absolute;
    left: 79%;
    top: 53%;
    transform: translate(-50%, -50%);
    width: 100px;
}

.landing .landing-text>img:nth-child(3){
    position: absolute;
    position: absolute;
    left: 72%;
    top: 77%;
    transform: translate(-50%, -50%);
    width: 58px;
}

.landing .landing-text h1{
    direction: rtl;
    text-align: center;
    text-align: center;
    width: 65%;
    line-height: 70px;
    font-weight: bold;
    font-size: 55px;
    color: #181923;
    margin-bottom: 36px;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    gap: 6px;
}

.landing .landing-text h1 span{
    color: #0864AF;
}


.landing .landing-text .links{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 38%;
}

.landing .landing-text .links a img{
    width: 22px;
}




/* dark-mode */

.landing.dark-mode .landing-text h1 {
    color: #fff;
}

.landing.dark-mode .landing-text h1 span {
    color: #D95D13;
}



/* screens */
@media(max-width:950px){

    .landing .landing-text {
        padding-top: 79px;
        min-height: 453px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .landing .landing-text>img:nth-child(1) {
        position: absolute;
        left: 18%;
        top: 30%;
        transform: translate(-50%, -50%);
        width: 50px;
    }

    .landing .landing-text>img:nth-child(2) {
        position: absolute;
        top: 0;
        right: 0;
        position: absolute;
        left: 73%;
        top: 27%;
        transform: translate(-50%, -50%);
        width: 61px;
    }

    .landing .landing-text>img:nth-child(3) {
        position: absolute;
        position: absolute;
        left: 16%;
        top: 92%;
        transform: translate(-50%, -50%);
        width: 38px;
    }


    .landing .landing-text h1 {
        direction: rtl;
        text-align: center;
        text-align: center;
        width: 100%;
        line-height: 32px;
        font-weight: bold;
        font-size: 23px;
        margin-bottom: 36px;
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
        gap: 6px;
        z-index: 15;
    }

    .landing .landing-text .links {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    
}