.services .row>div{
    margin-bottom: 120px;
}

.services .text{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    margin-bottom: 50px;
    margin-top: 50px;
}



.services .text ul{
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
    margin-top: 22px !important;
}

.services .text ul li{
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    gap: 5px;
}

.services .text ul li img{
    height: 28px;
}

.services .text ul li h2{
    font-size: 17px;
}


.services .text>div{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 50px;
    margin-top: 40px;
}


.services .text>div .normal-link{
    color: #000;
    text-decoration: underline !important;
    font-size: 15px;
}

.services .text>h3{
    font-size: 17px;
    color: #d95d13;
}




.services .text>div img{
    width: 100%;
    height: 50px;
}


.services .text .main-list{
    
}

.services .text .main-list>li{
    display: flex;
    flex-direction: column;
}

.services .text .main-list>li>h4{
    font-size: 20px;
    font-weight: 500;
    color: #d95d13;
    direction: rtl;
    margin: 0;
    font-weight: 600;
}

.services .text .main-list .sub-list{
    margin: 0 !important;
    padding-right: 67px;
    gap: 0;
}


.services .text .main-list .sub-list li{
    display: list-item;
    margin: 0 !important;
    list-style: disc !important;
    direction: rtl;
}

.services .text .main-list .sub-list li h5{
    font-size: 17px;
    font-weight: 500;
}



.services.dark-mode .text>div .normal-link {
    color: #fff;

}


.services.dark-mode .text .main-list .sub-list li {
    color: #fff;
}
.services.dark-mode .text .main-list .sub-list li h5 {
    color: #fff;
}

.services.en .text {
    direction: rtl;
}

.dark-mode .services .text .main-list .sub-list li {
    color: #fff;
}

.dark-mode .services .text>div .normal-link {
    color: #fff;
}