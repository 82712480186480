.about{
    margin-bottom: 178px;
}

.about .video{
    position: relative;
}


.about .video video{
    width: 100%;
    height: 425px;
    object-fit: cover;
    border-radius: 10px;
}


.about .video>img{
    position: absolute;
    bottom: -53px;
    left: -65px;
    z-index: -1;
}

.about .video .play-btn{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.about .video .play-btn button{
    width: 70px;
    height: 70px;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    background-color: #fff;
}

.play-btn button{
    z-index: 15;
    position: relative;
}

.about .video .play-btn button img{
    width: 22px;
}

.about .video .play-btn span:nth-child(2){
    width: 90px;
    height: 90px;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    background-color: transparent;
    position: absolute;
    border: 1px solid #ffffff78;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.about .video .play-btn span:nth-child(3){
    width: 110px;
    height: 110px;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    background-color: transparent;
    position: absolute;
    border: 1px solid #ffffff78;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.about .text{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.about .text .read-more{
    display: flex;
    flex-direction: row-reverse;
    gap: 15px;
    margin-top: 46px;
    margin-bottom: 45px;
}

.about .text .read-more div{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}


@media(max-width:950px){
    .about .video {
        position: relative;
        margin-bottom: 96px;
    }

    .about .video video {
        width: 100%;
        height: 361px;
        object-fit: cover;
        border-radius: 10px;
    }

    .about .text .read-more {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-top: 46px;
        margin-bottom: 45px;
    }

    .about .text .read-more div {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
}


/* en */


.about.en {
    direction: rtl;
}

.about.en .video>img {
    right: -65px;
    left: 0;
}

