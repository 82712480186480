footer{
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #FDF7F3;
}

footer .footer-content form{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
}

footer .footer-content form input{
    width: 100%;
    height: 42px;
    text-align: right;
    padding-right: 15px;
    border-radius: 50px;
    border: none;
    outline: none;
    background-color: #eee9;
}


footer .footer-content form button{
    width: 100%;
    border: none;
    outline: none;
}

footer .footer-content ul{
    padding: 0;
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    margin-top: 20px !important;
    gap: 0;
    flex-wrap: wrap;
    text-align: right;
}



footer .footer-content ul li{
    width: 50%;
    margin-bottom: 8px;
}

footer .footer-content ul li a{
    color : #000;
}


footer .footer-content .logo{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

footer .footer-content .logo img{
    width: 150px;
}

.copy-right{
    background-color: #FDF7F3;
}


.copy-right-content{
    padding-top: 20px;
    border-top: 1px solid #00000042;
    display: flex;
    flex-direction: row;
    padding-bottom: 20px;

}


.copy-right .left{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.copy-right h3{
    font-size: 17px;
    direction: rtl;
}



.copy-right .socials ul{
    padding: 0;
    display: flex;
    flex-direction: row;
    gap: 17px;
}


.copy-right .socials ul li a{
    width: 26px;
    display: flex;
}

footer.dark-mode {
    
    background-color: #2D2E3A;
}
.copy-right.dark-mode{
    background-color: #2D2E3A;

}
footer.dark-mode .footer-content ul li a {
    color: #808080;
}

footer.dark-mode .footer-content form input {
    background-color: #ffffff08;
}
.copy-right.dark-mode .socials ul li a {
    filter: invert(1);
}

.copy-right.dark-mode h3 {
    color: #fff;
}

.copy-right.dark-mode .copy-right-content {
    border-top: 1px solid #ffffff2b;
}

@media(max-width:950px){
    .copy-right .left {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 15px;
    }

    footer .footer-content {
        margin-bottom: 40px;
    }
    
}

footer.en {
    direction: rtl;
}

footer.en .footer-content ul {
    text-align: left;
}

.copy-right.en h3 {
    direction: ltr;
}