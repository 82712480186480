.temp-pop-up {
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    z-index: 98509590;
}
.temp-pop-up .temp-pop-up-content {
    padding: 50px;
    border-radius: 15px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.21);
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.temp-pop-up .temp-pop-up-content h1 {
    font-size: 24px;
    font-weight: 600;
    color: #000;
}

.temp-pop-up .temp-pop-up-content h2 {
    font-size: 20px;
    font-weight: 600;
}