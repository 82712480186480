.prices-calc{
    margin-top: 120px;
    margin-bottom: 120px;
    position: relative;

}

.prices-calc .calculater{
    height: 480px;
    border-radius: 8px;
    box-shadow: 0px 4px 104px 0px #0000000D;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 17px;
    align-items: center;
    background-color :#fff;

}

.prices-calc .calculater .header{
    position: relative;
    background-color: #d95d13;
    height: 50%;
    border-radius: 10px 10px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
}

.prices-calc .calculater .header img{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    object-fit: cover;
}

.prices-calc .calculater .header h3{
    font-size: 17px;
    color: #fff;
    margin-bottom: 0;
}


.prices-calc .calculater .header h1{
    direction: rtl;
    display: flex;
    flex-direction: row-reverse;
    gap: 15px;
    font-size: 75px;
    font-weight: 500;
    color: #fff;
    align-items: flex-end;
}

.prices-calc .calculater .header h1 span{
    font-size: 16px;
}

.prices-calc .calculater .calc-body{
    height: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 20px;
    width: 100%;
}

.prices-calc .calculater .calc-body div{
    height: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    border-bottom: 1px solid #00000029;
}


.prices-calc .calculater .calc-body div span{
    direction: rtl;
    font-size: 15px;
    font-weight: 400;
    color: #000;
}

.prices-calc .calculater .calc-body div span.not-selected{
    color: #00000075;
}

.prices-calc .calculater .print-invoice{
    border: none;
    outline: none;
    width: auto;
    width: 92%;

}

.prices-calc .info{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    height: 100%;
    padding-left: 48px;
}


.prices-calc .info .inputs {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    gap: 15px;
}


.prices-calc .info .inputs select{
    width: 40%;
    height: 50px;
    border-radius: 6px;
    border: 1px solid #0000002b;
    direction: rtl;
    padding: 5px;
}


.prices-calc .info .inputs input{
    width: 58%;
    height: 50px;
    border-radius: 6px;
    border: 1px solid #0000002b;
    direction: rtl;
    padding: 5px;
}


.prices-calc .info h4{
    direction: rtl;
    font-size: 18px;
    font-weight: 500;
    color: #000;
    margin-bottom: 30px;
}


.prices-calc .info .messages-number-options{
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 10px;
    flex-wrap: wrap;
}

.prices-calc .info .messages-number-options button{
    padding-left: 40px;
    padding-right: 40px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    height: 35px;
    background: transparent;
    border-radius: 50px;
    color: #fff;
    font-size: 15px;
    text-align: center;
    min-width: 180px;
    border: none;
    outline: none;
    color: #D95D13;
    border: 1px solid;
    margin: 0;
    margin-bottom: 18px;
    background-color: #fcefe8;
    transition: 0.5s;
}

.prices-calc .info .messages-number-options button.active{
    background-color: #D95D13;
    color: #fff;


}

.prices-calc.dark-mode .info .inputs input {
    background-color: #2D2E3A;
}

.prices-calc.dark-mode .info .inputs select {
    background-color: #2D2E3A;
    color : #fff;

}

.prices-calc.dark-mode .info h4 {;

    color: #fff;
}



.prices-calc.dark-mode .info .messages-number-options button {
    color: #fff;
    border: 1px solid #D95D13;

    background-color: #fcefe817;

}

.prices-calc.dark-mode .calculater {
    
    background-color: #2D2E3A;
}

.prices-calc.dark-mode .calculater .calc-body div span {
    color: #fff;
}

@media(max-width:950px){
    .prices-calc .info .messages-number-options button {
        padding-left: 19px;
        padding-right: 19px;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 35px;
        background: transparent;
        border-radius: 50px;
        font-size: 15px;
        text-align: center;
        min-width: 110px;
        border: none;
        outline: none;
        border: 1px solid;
        margin: 0;
        margin-bottom: 18px;
        transition: 0.5s;
    }

    .prices-calc .info .messages-number-options {
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-end;
        justify-content: flex-start;
        gap: 10px;
        flex-wrap: wrap;
        width: 100%;
    }
}



/* en */

.prices-calc.en {
    direction: rtl;
}

.prices-calc.en .info .inputs input {
    direction: ltr;
    text-align: left;
}

.prices-calc.en .info .inputs select {
    direction: ltr;
    text-align: left;
}

.prices-calc.en .calculater .calc-body div span {
    direction: ltr;
}

.prices-calc.en .info h4 {
    direction: ltr;

}