.finger-print-dev-text{
    margin-bottom: 120px;
}

.finger-print-dev-text .text{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    height: 100%;
    padding: 50px;
}



.finger-print-dev-text .image{
    position: relative;
}

.finger-print-dev-text .image img:nth-child(2){
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
}

.finger-print-dev-text.en .text {
    direction: rtl;
}