.plans{
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: #FDF7F3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.plans .plans-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}


.plans .plans-header div{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 17px;
    justify-content: flex-start;
}


.plans .all-plans{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    padding: 15px;
    justify-content: center;
    min-height: 473px;
}

.plans .all-plans .plan{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    min-height: 473px;
    justify-content: center;
    padding: 15px;
    background-color: #fff;
    border-radius: 10px;
}

.plans .all-plans .plan img{
    width: 115px;
}

.plans .all-plans .plan h2{
    font-size: 20px;
    font-weight: 700;
    color: #000;
}

.plans .all-plans .plan h1{
    display: flex;
    gap: 15px;
    font-size: 50px;
    font-weight: 800;
    color: #d95d13;
    align-items: center;
}

.plans .all-plans .plan h1 span{
    font-size: 16px;
    font-weight: 500;
    color: #000;
}

.plans .all-plans .plan p{
    font-size: 16px;
    text-align: center;
    direction: rtl;
    line-height: 30px;
}

.plans .all-plans.finger-print .plan{

}




.plans .finger-print .plan{
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    width: 100%;
    border-radius: 8px;
    background-color: #fdf7f3;
    min-height: 442px;
}


.plans .finger-print .plan .top{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 11px;
    justify-content: space-between;
    padding-bottom: 15px;
    border-bottom: 1px solid #00000021;
}


.plans .finger-print .plan .top img{
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.plans .finger-print .plan .top h1{
    font-size: 40px;
    font-weight: 900;
    color: #000;
}
.plans .finger-print .plan .top h1 span{
    font-size: 27px;
    font-weight: 400;
}

.plans .finger-print .plan .top h2{
    direction: rtl;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 9px;
    font-size: 26px;
    color: #d95d13;
    font-weight: 500;
    font-weight: 800;
}

.plans .finger-print .plan .top h2 span{
    font-size: 18px;
    color: #5d5c61;
    font-weight: 500;
}

.plans .finger-print .plan .top h3{
    font-size: 16px;
    color: #000;
}

.plans.dark-mode {
    background-color: transparent;
}

.plans.dark-mode .all-plans .plan {
    background-color: #2D2E3A;
}

.plans.dark-mode .all-plans .plan h2 {
    color: #fff;
}
.plans.dark-mode .all-plans .plan h1 span {
    color: #fff;
}


.plans.dark-mode .all-plans .plan p {
    color: #fff;
    
}
@media(max-width:950px){
    .plans .plans-header {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        gap: 25px;
    }
    .plans .all-plans {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        padding: 15px;
        justify-content: center;
        min-height: 473px;
    }

    .plans .all-plans .plan h1 {
        display: flex;
        gap: 15px;
        font-size: 35px;
        font-weight: 800;
        align-items: center;
    }
    
}

/* English */

.plans.en .plans-header {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
}