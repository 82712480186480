.sms-services-text{
    margin-bottom: 150px;
}

.sms-services-text .text{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 20px;
    padding: 50px;
    justify-content: center;
    height: 100%;
    width: 100%;
}




.sms-services-text .image{
    position: relative;
}

.sms-services-text .image img:nth-child(2){
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
}