.our-message{
    height: 450px;
    position: relative;
}

.our-message>img{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.3;
}


.our-message .text{
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.our-message .image{
    height: 320px;
}

.our-message .image img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}


@media(max-width:950px){
    .our-message .image img {
        width: 100%;
        height: 200px;
        object-fit: contain;
    }
}