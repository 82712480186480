.courses-categories{
    margin-top: 120px;
    margin-bottom: 120px;
}

.courses-categories .header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 33px;
    margin-top: 33px;
}


.courses-categories .category{
    position: relative;
}


.courses-categories .category img{
    border-radius: 10px;
    object-fit: cover;
}


.courses-categories .category .overlay{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    border-radius: 10px;
}

.courses-categories .category h1{
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 20px;
    direction: rtl;
    color: #fff;
    font-weight: 600;
}


@media(max-width:950px){
    .courses-categories .header {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-end;
        justify-content: center;
        margin-bottom: 33px;
        margin-top: 33px;
        gap: 20px;
    }

    .courses-categories .category {
        position: relative;
        margin-bottom: 60px;
    }
}


/* en */

.courses-categories.en .header {

    direction: rtl;
}

.courses-categories .category h1 {
    bottom: 10px;
    left: 10px;
    right: 0;
    direction: ltr;
}