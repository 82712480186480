.all-courses{
    position: relative;
    margin-bottom: 60px;
}

.all-courses .course{
    margin-bottom: 110px;
}

.all-courses .course img{
    object-fit: cover;
    border-radius: 9px;
}


.all-courses .course .text{
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
}


.all-courses .course .text h1{
    font-size: 20px;
    font-weight: 600;
    direction: rtl;
    color: #000;
}

.all-courses .course .text a{
    margin-top: 20px;
    font-size: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    color: #d95d13;
    font-weight: 500;
}

.all-courses .slider-btns{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 385px;
    justify-content: space-between;
}

.all-courses.dark-mode .course .text h1 {
    color: #fff;
}


/* en */

.all-courses.en .course .text {
    direction: rtl;
}