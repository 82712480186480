.faqs{
    margin-top: 100px;
    margin-bottom: 100px;
}


.faqs .header h1{
    text-align: center;
    margin-bottom: 57px;
}


.faqs .question{
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #00000057;
}

.faqs .question .header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.faqs .question .header button{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid #d95d13;
    background-color: transparent;
    color: #d95d13;
    font-size: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
}

.faqs .question .header button.active{
    background-color: #d95d13;
    color: #fff;
    
}


.faqs .question .header h2{
    direction: rtl;
}


.faqs .content{
    display: none;
}


.faqs .content.active{
    display: block;
}




.faqs .show-more{
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}



@media(max-width:950px){
    .faqs {
        margin-left:  0 !important;
    }
}

/* en */

.faqs.en .question .header {
    flex-direction: row-reverse;
}

.faqs.en .header h1 {
    text-align: left;
}
