
.prices-text .text{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.prices-text .text ul{
    padding: 0;
    direction: rtl;
}

.prices-text .text ul li{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.prices-text .text ul li button{
    margin-right: 5px;
    color: #d95d13;
    text-decoration: underline !important;
    background-color: transparent;
    outline: none;
    border:none;
}

.prices-text .image{
    position: relative;
}




.prices-text .image img:nth-child(2){
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
}


.prices-text.dark-mode .text ul li {
    color: #fff;
}


/* en */

.prices-text.en .text ul {
    direction: ltr;
}