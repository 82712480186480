.services-steps{
    margin-bottom: 100px;
}


.services-steps .header{
    padding-top: 90px;
    padding-bottom: 90px;
}


.services-steps .header h1{
    text-align: center;
}

.services-steps .steps{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.services-steps .steps .step{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 350px;
}


.services-steps .steps .step img{
    width: 150px;
    height: 150px;
    object-fit: contain;
    border-radius: 50%;
}

.services-steps .steps .step h2{
    margin-top: 27px;
    text-align: center;
    direction: rtl;

}

.services-steps .steps .arrow{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 350px;
}


.services-steps .steps .arrow img{
    width: 100%;
    object-fit: contain;
}


@media(max-width:950px){
    .services-steps .steps {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
}