.not-found{
    position: relative;
}

.not-found>img:nth-child(1){
    position: absolute;
    inset: 0;
    left: 0;
    top: 0;
    object-fit: cover;
    z-index: -1;
}

.not-found>img:nth-child(2){
    position: absolute;
    inset: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.not-found .content{
    min-height: 100vh;
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.not-found .content h1{
    font-size: 40px;
    font-weight: 600;
    color: #000;
}

.not-found .content p{
    font-size: 16px;
    color: #0000008a;
}

.not-found .content img{
    width: 500px;
    object-fit: contain;
}


.not-found .content a{
    margin-top: 31px;
}

.not-found.dark-mode .content h1 {
    color: #fff;
}
.not-found.dark-mode  .content p {
    font-size: 16px;
    color: #fff;
}

@media(max-width:950px){
    .not-found .content img {
        width: 100%;
    }

    .not-found>img:nth-child(1) {
        position: absolute;
        inset: 0;
        left: 0;
        top: 0;
        object-fit: cover;
        width: 100%;
        z-index: -1;
        height: 100%;
    }
}