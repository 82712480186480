.applied-courses{
    margin-bottom: 120px;
}

.applied-courses .screens-btns{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-start;
}

.applied-courses .screens-btns button{
    background-color: #f2f2f2;
    border: none;
    outline: none;
    width: fit-content;
    height: 45px;
    padding-left: 30px;
    padding-right: 22px;
    border-radius: 50px;
    margin-right: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    direction: rtl;
    font-size: 16px;
    font-weight: 400;
    transition: 0.5s;
    cursor: pointer;
}

.applied-courses .screens-btns button.active{
    background-color: #ce5812;
    color: #fff;
}

.applied-courses .screens .screen{
    display: none;
    background-color: #FDF7F3;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
    height: 170px;
    border-radius: 5px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.applied-courses .screens .screen.active{
    display: flex;
}

.applied-courses .screens .screen img{
    width: 74px;
}

.applied-courses .screens .screen h2{
    direction: rtl;
    font-size: 23px;
    margin: 0;
    font-weight: 500;
    color: #737276;
}

.applied-courses .screens .screen a{
    font-size: 20px;
    font-weight: 500;
    color: #ce5812;
    text-decoration: underline !important;
}


/* dark-mode */


.applied-courses.dark-mode .screens-btns button:not(.link-btn-color) {
    background-color: #2D2E3A;
    color: #fff;

}
.applied-courses.dark-mode .screens-btns button.active {
    background-color: #ce5812;
    color: #fff;
}

.applied-courses.dark-mode .screens .screen {
    background-color: #2D2E3A;
}

.applied-courses.dark-mode .screens .screen h2 {
    color: #fff;
}


@media(max-width:950px){
    .applied-courses .screens-btns {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 17px;
    }

    .applied-courses .screens .screen {
        display: none;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 15px;
        height: 170px;
        border-radius: 5px;
        margin-top: 30px;
        margin-bottom: 30px;
        flex-wrap: wrap;
        gap: 0;
    }

    .applied-courses .screens .screen h2 {
        direction: rtl;
        font-size: 17px;
        margin: 0;
        font-weight: 500;
    }
}

/* en */


.applied-courses.en .screens .screen {
    direction: rtl;
    text-align: left;
}

.applied-courses .screens .screen h2 {
    direction: ltr;

}