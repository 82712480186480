.pay-ways-pop-up{
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: #00000059;
    z-index: 4000;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;

}


.pay-ways-pop-up.fade-out {
    opacity: 0;
    pointer-events: none;
}


.pay-ways-pop-up .pop-content{
    background-color: #fff;
    width: 70%;
    height: 600px;
    border-radius: 10px;
    position: relative;
    padding: 31px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.pay-ways-pop-up .pop-content .header 
{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
}

.pay-ways-pop-up .pop-content .header button{
    background-color: transparent;
    outline: none;
    border: none;
    color: #000;
    font-size: 22px;
}


.pay-ways-pop-up .pop-content .header h1{
    font-size: 31px;
    margin-bottom: 16px;
}

.pay-ways-pop-up .pop-content .header p{
    margin: 0;
    font-size: 15px;
    font-weight: 400;
}

.pay-ways-pop-up .pop-content .header div{
    direction: rtl;
}


.pay-ways {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 50px;
    width: 100%;
}

.pay-ways .way{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
    width: 50%;
}


.pay-ways .way img{
    width: 160px;
    height: 160px;
    object-fit: contain;
}



.pay-ways .way h2{
    font-size: 24px;
    font-weight: 600;
    color: #000;
    margin-top: 10px;
    margin-bottom: 15px;
}


.pay-ways .way p{
    text-align: right;
    font-size: 14px;
    direction: rtl;
    font-weight: 500;
    color: #686868;
}

.pay-ways .way a{
    margin-top: 30px;
}


.pay-ways .way button{
    margin-top: 30px;
}


@media(max-width:950px){
    .pay-ways-pop-up .pop-content {
        background-color: #fff;
        width: 99%;
        height: 88%;
        border-radius: 10px;
        position: relative;
        padding: 31px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .pay-ways {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        gap: 20px;
    }

    .pay-ways .way {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        text-align: right;
        width: 100%;
    }

    .pay-ways {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        gap: 20px;
    }

    .pay-ways .way img {
        width: 99px;
        height: 86px;
        object-fit: contain;
    }

    .pay-ways .way h2 {
        font-size: 16px;
        font-weight: 600;
        color: #000;
        margin-top: 10px;
        margin-bottom: 15px;
    }
    .pay-ways .way p {
        text-align: right;
        font-size: 13px;
        direction: rtl;
        font-weight: 500;
        color: #686868;
    }
}

.pay-ways-pop-up.en .pop-content .header {

    direction: rtl;
}


.pay-ways-pop-up.en .pop-content .header p {
    text-align: left;
    direction: ltr;
}


.en .pay-ways .way {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: right;
    width: 50%;
}