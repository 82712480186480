.team-members{
    margin-bottom: 150px;
}

.team-members .header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 50px;
    padding-bottom: 60px;
}

.team-members .header h1{
    text-align: center;
}

.team-members .team-slider{
    position: relative;
}

.team-members .team-slider .members{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
}

.team-members .team-slider .members .mem{
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 20px;
    min-height: 486px;
}


.team-members .team-slider .members .mem img{
    width: 250px;
    height: 250px;
    border-radius: 50%;
    object-fit: contain;
}



.team-members .team-slider .members .mem h3{
    font-size: 21px;
    font-weight: 600;
    color: #000;
}


.team-members .team-slider .members .mem h4{
    font-size: 16px;
    font-weight: 500;
}

.team-members .team-slider .slider-btns{
    margin-top: 29px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    justify-content: center;
    gap: 30px;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 15;
}

.team-members .team-slider .slider-btns button{
    width: 50px;
    height: 50px;
    background-color: #2d7bbb;
    color: #fff;
    border-radius: 50%;
    border: none;
    outline: none;
    font-size: 22px;
}

.team-members .swiper-pagination {
    position: absolute;
    text-align: center;
    transition: .3s opacity;
    transform: translate3d(0, 0, 0);
    z-index: 10;
    left: 0 !important;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-left: 29px;
}

.team-members.dark-mode .team-slider .members .mem h3 {
    color: #fff;
}

.team-members.dark-mode .team-slider .members .mem h4 {
    color: #fff;
}

@media(max-width:950px){
    .team-members .team-slider .members .mem img {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        object-fit: contain;
    }

    .team-members .team-slider .members .mem h3 {
        font-size: 19px;
        font-weight: 600;
    }

    .team-members .team-slider .members .mem h4 {
        font-size: 15px;
        font-weight: 500;
    }

    .team-members .team-slider .members {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 0;
        flex-wrap: wrap;
        margin-bottom: 56px;
    }


    .team-members .team-slider .members .mem {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        gap: 20px;
        min-height: 286px;
    }
}
