.parallex-element{
    width: 100%;
    height: 650px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;

}

.parallex-element img{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.parallex-element .overlay{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: #094a84a1;
    z-index: 1;
}

.parallex-element h2{
    font-size: 23px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    position:relative;
    z-index: 1;
}

.parallex-element h1{
    font-size: 50px;
    font-weight: 700;
    color: #fff;
    position:relative;
    z-index: 1;
}

.parallex-element p{
    text-align: center;
    width: 40%;
    color: #fff;
    font-size: 15px;
    margin-top: 17px;
    font-weight: 300;
    position:relative;
    z-index: 1;
}


.numbers{
    margin-top: -109px;
    margin-bottom: 150px;
    z-index: 20;
    position:relative;

}

.numbers .row {
    background-color: #fff;
    border-radius: 15px;
}

.numbers .number{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 250px;
    padding-top: 50px;
    padding-bottom: 50px;
}


.numbers .number h1{
    font-size: 65px;
    font-weight: 700;
    color: #d95d13;
    letter-spacing: 7px;
}

.numbers .number p{
    font-size: 15px;
    font-weight: 700;
    width: 50%;
    text-align: center;
}

/* dark-mode */

.numbers.dark-mode .row {
    background-color: #2D2E3A;
}

.numbers.dark-mode .number h1 {
    color: #fff;
}
.numbers.dark-mode  .number p {
    color: #fff;
}

@media(max-width:950px){
    .parallex-element h1 {
        font-size: 31px;
        font-weight: 700;
        position: relative;
        z-index: 1;
        text-align: center;
    }

    .parallex-element p {
        text-align: center;
        width: 94%;
        font-size: 15px;
        margin-top: 17px;
        font-weight: 300;
        position: relative;
        z-index: 1;
    }

    .parallex-element h2 {
        font-size: 21px;
        font-weight: 400;
        text-align: center;
        position: relative;
        z-index: 1;
    }
}