.contactad{
    position: relative;
    padding-bottom: 50px;
}

.contactad>img{
    position: absolute;
    z-index: -1;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.2;
}


.contactad .contactad-content{
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.contactad .contactad-content h1{
    text-align: center;
    width: 51%;
    margin-bottom: 53px;
    line-height: 73px;
}

.contactad .contactad-content a{
    
}



@media(max-width:950px){
    .contactad .contactad-content h1 {
        text-align: center;
        width: 100%;
        margin-bottom: 53px;
        line-height: 73px;
    }
}