.available-devices{
    margin-bottom: 120px;
}

.available-devices .header{
    margin-bottom: 30px;
}


.available-devices .devices-slider{
    position: relative;
}

.available-devices .devices-slider .back-slider{
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 80%;
    height: 100%;
    border-radius: 10px;
    background-color: #fdf7f3;
    z-index: -1;
}

.available-devices .devices-slider .back-slider img{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.available-devices .devices-slider .device{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;
}

.available-devices .devices-slider .device .image{
    width: 30%;
    height: 100%;
}


.available-devices .devices-slider .device .image img{
    width: 100%;
    height: 450px;
    object-fit: contain;
}

.available-devices .devices-slider .device .text{
    direction: rtl;
    display: flex;
    flex-direction: column;
    gap: 17px;
    align-items: flex-start;
    width: 70%;
    padding: 35px;
}

.available-devices .devices-slider .device .text .side-side{
    display: flex;
    flex-direction: row;
    align-items: self-start;
    flex-wrap: wrap;
    width: 100%;
    padding: 23px;
}

.available-devices .devices-slider .device .text .side-side li{
    width: 50%;
    display: list-item;
    list-style: disc;
    direction: rtl;
    min-height: 62px;
    padding-left: 38px;
    margin-bottom: 2px;
    text-align: right;
}

.available-devices .devices-slider .device .text button{
    margin-top: 0;
}

.available-devices .devices-slider .device .text .normal{
    display: flex;
    flex-direction: column;
    align-items: self-start;
    width: 100%;
    padding: 23px;
    gap: 15px;
}


.available-devices .devices-slider .device .text .normal li{
    display: list-item;
    list-style: disc;
}

.available-devices .devices-slider .device .text .line{
    width: 606px;
    height: 1px;
    background-color: #00000017;
}

.available-devices .devices-slider .device .text h3{
    font-size: 29px;
    font-weight: 600;
    color: #d95d13;
    display: flex;
    flex-direction: row-reverse;
    gap: 5px;
    align-items: flex-end;
}

.available-devices .devices-slider .device .text h3 span{
    font-size: 15px;
    font-weight: 400;
    color: #616161;
}

.available-devices .devices-slider .device .text h4{
    font-size: 15px;
    font-weight: 600;
    color: #000;
}

.available-devices .slider-btns{
    position: absolute;
    bottom: 3%;
    left: 20%;
    transform: translateX(-50%);
    z-index: 15;
}


.available-devices.dark-mode .devices-slider .back-slider {

    background-color: #2D2E3A;
}

.available-devices.dark-mode .devices-slider .device .text .side-side li {
    color: #fff;
}
.devices-benefits-text.dark-mode .text ul li {
    color: #fff;
}

.available-devices.dark-mode .devices-slider .device .text .normal {

    color: #fff;
}


.available-devices.dark-mode .devices-slider .device .text h4 {
    color: #fff;
}

.available-devices .devices-slider .device .text h3 span {
    color: #fff;
}
@media(max-width:950px){
    .available-devices .devices-slider .device {
        flex-direction: column;
        margin-bottom: 101px;
    }

    .available-devices .devices-slider .device .text {
        width: 100%;
        padding: 10px;
    }


    .available-devices .devices-slider .back-slider {
        width: 100%;
        height: 100%;
    }

    .available-devices .devices-slider .device .image {
        width: 100%;
        height: 350px;
        object-fit: contain;
        padding: 19px;
    }

    .available-devices .devices-slider .device .image img {
        width: 100%;
        height: 279px;
        object-fit: contain;
    }

    .available-devices .devices-slider .device .text .side-side li {
        width: 100%;
        
    }

    .available-devices .devices-slider .device .text h3 {
        font-size: 18px;
        
    }

}

.available-devices.en .devices-slider .device .text .side-side li {
    direction: rtl;
    padding-left: 0;
    text-align: left;
    direction: ltr;
    padding-right: 52px;
}

.available-devices.en .devices-slider .device .text .side-side {
    direction: ltr;
}

.available-devices.en .devices-slider .device .text {

    direction: ltr;
}

