.prices{
    margin-top: 120px;
    margin-bottom: 120px;
    position: relative;

}

.prices>img{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    z-index: -1;
}


.prices .prices-content{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.prices .prices-content .price{
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #00000033;
    padding-bottom: 0;
    justify-content: space-between;
    height : 100%;
    background-color :#fff;
}

.prices .prices-content .price:nth-child(1){
    border-radius: 10px 0 0 0;
}


.prices .prices-content .price:nth-child(4){
    border-radius:  0 10px 0 0;
}


.prices .prices-content .price .top{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: center;
}

.prices .prices-content .price .top img{
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.prices .prices-content .price .top h2{
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 18px;
}


.prices .prices-content .price ul{
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}


.prices .prices-content .price ul li{
    width: 100%;
    text-align: center;
    padding-top: 6px;
    padding-bottom: 6px;
    border: 1px solid #00000026;
    direction: rtl;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    height: 40px;
    border-right: 0px solid #00000026;
    border-left: 0px solid #00000026;

}

.prices .prices-content .price ul li:nth-child(odd){
    background-color: #fdf7f3;

}

.prices .prices-content .price ul li i{
    
}

.prices .prices-content .price ul li span{
    font-size: 25px;
    font-weight: 700;
    color: #d95d13;
    margin-left: 7px;
}




.prices .prices-content .price.info-header{
    border : none;
}

.prices .prices-content .price.info-header ul li{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    font-size: 14px;
    font-weight: 700;
    padding-right: 7px;
    border-right: 1px solid #00000026;

}


.prices .prices-content .price.info-header h1{
    padding: 40px;
    text-align: right;
    font-weight: 800;
    color: #000;
}

.prices .note{
    margin-top: 15px;
}

.prices .note h3{
    text-align: right;
    font-size: 16px;
    color: #0864af;
}

.prices.dark-mode .prices-content .price {
    background-color: #2D2E3A;
}

.prices.dark-mode .prices-content .price.info-header ul li {
    color: #fff;
}

.prices.dark-mode .prices-content .price ul li:nth-child(odd) {
    background-color: #fdf7f326;
}

.prices.dark-mode .prices-content .price ul li {
    color: #fff;

}

.prices.dark-mode .prices-content .price.info-header h1 {

    color: #fff;
}


@media(max-width:950px){
    .prices .prices-content .price {
        width: 448px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 0;
        justify-content: space-between;
        height: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }

    .prices .prices-content {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        overflow-x: scroll;
    }
}


/* en */


.prices.en .prices-content {
    direction: rtl;
}

.prices.en .prices-content .price.info-header h1 {
    text-align: left;
}

.prices.en .prices-content .price.info-header ul li {
    direction: ltr;
}


.prices .note h3 {
    text-align: left;
}