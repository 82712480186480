.contact-us {
    margin-top: 80px;
}

.contact-us .contact-info{
    height: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    background-color: #d95d13;
    position: relative;
    border-radius: 15px;
    padding: 20px;
    text-align: right;
}

.contact-us .contact-info>img{
    position: absolute;
    left: 0;
    object-fit: contain;
    bottom: 0;
}


.contact-us .contact-info h1{
    font-size: 35px;
    font-weight: 600;
    color: #fff;
}

.contact-us .contact-info p{
    direction: rtl;
    text-align: right;
    font-size: 13px;
    color: #fff;
    width: 83%;
}

.contact-us .contact-info .line{
    z-index: 1;
    margin-top: 20px;
    margin-bottom: 20px;
    height: 1px;
    width: 100%;
    display: flex;
    background-color: #ffffff2b;
    position: relative;
}

.contact-us .contact-info ul{
    z-index: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0;
}


.contact-us .contact-info ul li a{
    display: flex;
    flex-direction: row-reverse;
    gap: 5px;
    color: #fff;
    align-items: flex-start;
}

.contact-us .contact-info ul li a:hover{
    color:  #fff !important;
}

.contact-us .contact-info ul li a img{
    width: 19px;
}

.contact-us .contact-info .socials {
    margin-top: 103px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    gap: 17px;
    position: relative;
    z-index: 3;
}

.contact-us .contact-info .socials a img{
    width: 31px;
}


.contact-us .form-element{
    margin: 0;
}


.contact-us .form-element form{
    align-items: flex-end;
}


.contact-us .map{
    margin-top: 80px;
    margin-bottom: 80px;
}



.contact-us .map iframe{
    border-radius: 15px;
}



.contact-us.en .form-element form {
    direction: rtl;
}

.en .form-elementt form .inputs .input input {
    direction: ltr;
    padding-left: 16px;
    text-align: left;
}

.en .form-elementt form .inputs .input select {
    direction: ltr;
    padding-left: 16px;
    text-align: left;
}

.en .form-element form .inputs .input textarea {
    direction: ltr;
    padding-left: 16px;
    text-align: left;
}

.contact-us.en .contact-info {
    direction: rtl;
    text-align: left;
}

.contact-us.en .contact-info p {
    text-align: left;
    direction: ltr;
}