.price-invoice-popup{
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: #00000059;
    z-index: 4000;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;

}


.price-invoice-popup.fade-out {
    opacity: 0;
    pointer-events: none;
}


.price-invoice-popup .pop-content{
    background-color: #fff;
    width: 550px;
    height: 855px;
    border-radius: 10px;
    position: relative;
    padding: 31px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    position: absolute;
    top: 38%;
    left: 45%;
    transform: translate(-50%, -50%);
    border-radius: 0;
    scale: 0.8;
    padding-bottom: 0;
}

.price-invoice-popup .pop-content>img{
    position: absolute;
    top: 0;
    object-fit: contain;
    z-index: -1;
    width: 100%;
    left: 0;
    left: 50%;
    transform: translateX(-50%);
}


.price-invoice-popup .pop-content .btns{
    position: absolute;
    right: -42px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 7px;
}

.price-invoice-popup .pop-content .btns button{
    background-color: transparent;
    outline: none;
    border: none;
    color: #fff;
    font-size: 34px;
    border-bottom: 1px solid #fff;
}


.price-invoice-popup .pop-content .invoice-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #0000002e;
    padding-bottom: 10px;
    margin-bottom: 10px;
}



.price-invoice-popup .pop-content .invoice-header>div h2{
    font-size: 16px;
    font-weight: 600;
    color: #000;
}


.price-invoice-popup .pop-content .invoice-header>div h3{
    font-size: 14px;
}


.price-invoice-popup .pop-content .invoice-header img{
    width: 100px;
    height: 45px;
    object-fit: contain;
}



.price-invoice-popup .pop-content .invoice-info{
    text-align: right;
    direction: rtl;
    width: 100%;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #0000002e;
}

.price-invoice-popup .pop-content .invoice-info h4{
    display: flex;
    flex-direction: row-reverse;
    direction: rtl;
    justify-content: flex-end;
    font-size: 17px;
    font-weight: 400;
    gap: 15px;
    align-items: center;
}


.price-invoice-popup .pop-content .invoice-info h4 span{
    font-weight: 700;
    font-size: 14px;
}



.price-invoice-popup .pop-content .invoice-info p{
    text-align: right;
    width: 55%;
    font-size: 14px;
    font-weight: 400;
    margin-top: 15px;
}


.price-invoice-popup .pop-content .invoice-info .table-1{
    width: 100%;
    background-color: #fff;
}




.price-invoice-popup .pop-content .invoice-info .table-1 th{
    background-color: #fdf7f3;
    border: 1px solid #0000000f;
    padding: 5px;
}

.price-invoice-popup .pop-content .invoice-info .table-1 td{
    padding: 5px;
    border: 1px solid #0000001c;
}

.price-invoice-popup .pop-content .invoice-info .table-1 h2{
    font-size: 14px;
    font-weight: 400;
    color: #000;
}

.price-invoice-popup .pop-content .invoice-info .table-1 h3{
    font-size: 14px;
    font-weight: 400;
    color: #000;
}


.price-invoice-popup .pop-content .company-info{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}



.price-invoice-popup .pop-content .company-info div h1{
    font-size: 17px;
    font-weight: 600;
}

.price-invoice-popup .pop-content .company-info img{
    width: 101px;
    height: 101px;
    object-fit: contain;
}

.price-invoice-popup .pop-content .contact-info{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}


.price-invoice-popup .pop-content .contact-info .qr-code{
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-right: 1px solid #00000038;
}


.price-invoice-popup .pop-content .contact-info .qr-code h5{
    font-size: 17px;
    font-weight: 500;
    color: #000;
}

.price-invoice-popup .pop-content .contact-info .qr-code img{
    width: 80px;
    height: 80px;
    object-fit: contain;
}

.price-invoice-popup .pop-content .contact-info .info{
    direction: rtl;
    padding: 15px;
    padding-right: 0;
}

.price-invoice-popup .pop-content .contact-info .info h1{
    font-size: 17px;
    font-weight: 600;
    color: #000;
}

.price-invoice-popup .pop-content .contact-info .info ul{
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 15px;
}

.price-invoice-popup .pop-content .contact-info .info ul li{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 8px;
    font-size: 14px;
    font-weight: 500;
}




.price-invoice-popup .pop-content .table-2{
    direction: rtl;
    padding: 15px;
    margin: 0px;
    position: relative;
    width: 100%;
}


.price-invoice-popup .pop-content .table-2 tr{
    border-bottom: 1px solid #0000002b;
}

.price-invoice-popup .pop-content .table-2 th{
    font-size: 20px;
    font-weight: 600;
    color: #000;
    padding: 15px;

}



.price-invoice-popup .pop-content .table-2 td{
    padding: 15px;
}



.price-invoice-popup .pop-content .table-2 h2{
    font-size: 11px;
    font-weight: 600;
    color: #000;
}


.price-invoice-popup .pop-content .table-2 h3{
    margin: 0;
    font-size: 11px;
    display: flex;
    flex-direction: row-reverse;
    text-align: right;
    justify-content: flex-end;
    gap: 8px;
    font-weight: 500;
    color: #000;
    align-items: center;
}



.price-invoice-popup .pop-content .table-2 h3 button{
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 22px;
}


@media(max-width:950px){
    .price-invoice-popup .pop-content {
        background-color: #fff;
        width: 550px;
        height: 855px;
        border-radius: 10px;
        position: relative;
        padding: 31px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        position: absolute;
        top: 23%;
        left: 17%;
        transform: translate(-50%, -50%);
        border-radius: 0;
        scale: 0.58;
        padding-bottom: 0;
    }
}





.price-invoice-popup.en .pop-content .invoice-info {
    text-align: left;
    direction: ltr;
}

.price-invoice-popup.en .pop-content .invoice-info p {
    text-align: left;
}

.price-invoice-popup.en .pop-content .company-info {
    direction: rtl;
}

.price-invoice-popup.en .pop-content .table-2 {
    direction: ltr;
}

.price-invoice-popup.en .pop-content .contact-info {
    flex-direction: row-reverse;
}

.price-invoice-popup.en .pop-content .contact-info .info {
    direction: ltr;
}

.price-invoice-popup .pop-content .contact-info .qr-code {
    border-left: 1px solid #00000038;
    border-right: 0;
}