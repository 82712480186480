.finger-print-prices{
    margin-top: 120px;
    margin-bottom: 120px;
    position: relative;

}
.finger-print-prices>img{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    z-index: -1;
}


.finger-print-prices .header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 80px;
}



.finger-print-prices .plan{
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    width: 100%;
    border-radius: 8px;
    background-color: #fdf7f3;
    min-height: 442px;
}


.finger-print-prices .plan .top{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 11px;
    justify-content: space-between;
    padding-bottom: 15px;
    border-bottom: 1px solid #00000021;
}


.finger-print-prices .plan .top img{
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.finger-print-prices .plan .top h1{
    font-size: 40px;
    font-weight: 900;
    color: #000;
}
.finger-print-prices .plan .top h1 span{
    font-size: 27px;
    font-weight: 400;
}

.finger-print-prices .plan .top h2{
    direction: rtl;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 9px;
    font-size: 26px;
    color: #d95d13;
    font-weight: 500;
    font-weight: 800;
}

.finger-print-prices .plan .top h2 span{
    font-size: 18px;
    color: #5d5c61;
    font-weight: 500;
}

.finger-print-prices .plan .top h3{
    font-size: 16px;
    color: #000;
}

.finger-print-prices.dark-mode .plan {

    background-color: #2D2E3A;
}

.finger-print-prices.dark-mode .plan .top h1 span {
    color: #fff;

}

.finger-print-prices.dark-mode .plan .top h1 {
    color: #fff;
}


.finger-print-prices.dark-mode  .plan .top h2 span {
    color: #fff;
}

.finger-print-prices.dark-mode  .plan .top h3 {
    color: #fff;
}

@media(max-width:950px){
    .finger-print-prices .header {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 80px;
        gap: 23px;
    }
}



/* en */

.finger-print-prices.en .header {
    flex-direction: row-reverse;
}